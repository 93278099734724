// extracted by mini-css-extract-plugin
export var tablet_min = "768px";
export var desktop_min_sm = "992px";
export var desktop_min = "1100px";
export var desktop_min_lg = "1220px";
export var popup_breakpoint = "1399px";
export var closeTimeout = "1000";
export var ModalPlaceholder = "W_cT";
export var ModalContent = "W_cV";
export var closeButton = "W_cW";
export var ModalOverlay = "W_cX";
export var visibleOverlay = "W_cY";
export var fadeIn = "W_cZ";
export var hiddenOverlay = "W_c0";
export var fadeOut = "W_c1";